<template>
  <div class="roleTab clearfix">
    <div class="apply-table">
      <global-table ref="libraryData" :tableField="tableField" :tableData="tableData"></global-table>

      <!-- 修改日期：2023-08-09 高天滨 -->
      <!-- 修改内容：对所有类型的送货需求，统一取消原因类型为如下取消原因枚举项（（不可抗力、直发、质量问题、PO延期、尾数无法凑包），当填写确认后调用接口同步SAP（当前逻辑是需求类型为JH：按计划到货的展示如下几类取消原因：  -->
      <!-- 删除内容：v-if="tableData[0].demandType === 8" -->
      <el-select v-model="cancelReasonClassify" placeholder="请选择取消原因" filterable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
      </el-select>
      <el-input type="textarea" v-model="desc" placeholder="请填写说明"></el-input>
    </div>
  </div>
</template>

<script>

import Component from '@/assets/js/components.js'
// import { request } from '@/assets/js/http.js'
import { tableField, tableData } from './ApplyBody.js'
export default {
  components: Component.components,
  name: 'ApplyBody',
  props: ['tabData', 'selectTabData'],
  data () {
    return {
      desc: '',
      tableField: tableField,
      tableData: tableData,
      cancelReasonClassify: '',
      options: [
        {
          value: '1',
          label: '不可抗力'
        },
        {
          value: '2',
          label: '直发'
        },
        {
          value: '3',
          label: '质量问题'
        },
        {
          value: '4',
          label: 'PO延期'
        },
        {
          value: '5',
          label: '尾数无法凑包'
        },{
          value: '6',
          label: '最小送货批调整'
        },
        {
          value: '7',
          label: '重复触发'
        },
        {
          value: '8',
          label: '需求无po可选'
        },
        {
          value: '9',
          label: '客户通知取消或延期（需求变更）'
        },
        {
          value: '10',
          label: '调整基地'
        }/* ,
        {
          value: '6',
          label: '其他'
        } */
      ]
    }
  },
  created () {
    this.tableData = this.selectTabData
  },
  mounted () {

  },
  methods: {

  },
  watch: {
  }
}
</script>
<style scoped lang="scss">
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
  .apply-table .el-textarea{
    padding: 20px 20px 0;
    width: calc(100% - 40px);
  }
  .apply-table .el-select{
    padding: 20px 20px 0;
  }
</style>
