export var tableField = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '200' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '160' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '280' },
  { label: '需求数量/单位', code: 'demandNumber', type: 'input', width: '160' },
  { label: '下发日期', code: 'issueTime', type: 'input', width: '140' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '178' }
]

export var tableData = [
]
